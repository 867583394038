import { useState, useEffect, useCallback, useRef } from 'react';
import './App.css';
import MonkeyAnimation from './components/MonkeyAnimation';
import bombAudio from "./assets/cinematic-boom.mp3"
import whooshAudio from "./assets/whoosh-metal-gate.mp3"
function App() {
  const [step, setStep] = useState('intro');
  const [currentWord, setCurrentWord] = useState('Are');
  const [isVisible, setIsVisible] = useState(true);
  const [noButtonStyle, setNoButtonStyle] = useState({});
  const [isBlurring, setIsBlurring] = useState(false);
  const [fireworks, setFireworks] = useState([]);

  const getRandomColors = useCallback(() => {
    const colorSets = [
      ['yellow', 'khaki', 'white', 'lime', 'gold', 'mediumseagreen'],
      ['pink', 'violet', 'fuchsia', 'orchid', 'plum', 'lavender'],
      ['cyan', 'lightcyan', 'lightblue', 'PaleTurquoise', 'SkyBlue', 'lavender'],
      ['#8FA69B', '#A6B5AE', '#C4CDCA', '#D8DFDD', '#E9ECEB', '#F7F9F8'],
      ['#FFD700', '#FFA500', '#FF6347', '#FF4500', '#FF8C00', '#DAA520'],
    ];
    return colorSets[Math.floor(Math.random() * colorSets.length)];
  }, []);

  const generateFireworks = useCallback(() => {
    const numberOfFireworks = Math.floor(Math.random() * 3) + 2;
    const newFireworks = [];

    for (let i = 0; i < numberOfFireworks; i++) {
      newFireworks.push({
        id: Date.now() + i,
        left: Math.random() * 100 + '%',
        top: Math.random() * 100 + '%',
        scale: Math.random() * 0.5 + 0.5,
        animationDelay: -Math.random() * 2 + 's',
        colors: getRandomColors()
      });
    }

    setFireworks(prev => [...prev, ...newFireworks]);
    setTimeout(() => {
      setFireworks(prev => prev.slice(numberOfFireworks));
    }, 2000);
  }, [getRandomColors]);
  const boom = useRef(new Audio(bombAudio));
  const whoosh = useRef(new Audio(whooshAudio));
  useEffect(() => {
    boom.current.preload = "auto"
    whoosh.current.preload = "auto"
  }, [])
  useEffect(() => {
    if (step === 'birthday') {
      generateFireworks();
      const interval = setInterval(generateFireworks, 1000);

      return () => clearInterval(interval);
    }
  }, [step, generateFireworks]);

  useEffect(() => {
    if (step === 'intro') {
      const sequence = ['Are', 'You', 'Ready?'];
      const currentIndex = sequence.indexOf(currentWord);

      if (currentIndex < sequence.length - 1) {
        const timer = setTimeout(() => {
          setIsVisible(false);
          setTimeout(() => {
            setCurrentWord(sequence[currentIndex + 1]);
            setIsVisible(true);
          }, 500);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        const timer = setTimeout(() => {
          boom.current.play().catch(error => console.log('Audio play failed:', error))
          setStep('window');
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
  }, [currentWord, step]);

  const handleNoClick = () => {
    const maxX = window.innerWidth - 150;
    const maxY = window.innerHeight - 60;
    const newX = Math.random() * maxX;
    const newY = Math.random() * maxY;
    setNoButtonStyle({
      position: 'fixed',
      left: newX + 'px',
      top: newY + 'px',
      transform: 'translate(0, 0)'
    });
  };

  const handleYesClick = () => {
    setIsBlurring(true);
    whoosh.current.play().catch(error => console.log('Audio play failed:', error));
    setTimeout(() => {
      setStep('birthday');
    }, 2000);
  };

  if (step === 'birthday') {
    return (
      <div className="App">
        <MonkeyAnimation />
        <div className="birthday-container">
          {fireworks.map(fw => (
            <div
              key={fw.id}
              className="firework"
              style={{
                left: fw.left,
                top: fw.top,
                transform: `scale(${fw.scale})`,
                animationDelay: fw.animationDelay,
                '--color1': fw.colors[0],
                '--color2': fw.colors[1],
                '--color3': fw.colors[2],
                '--color4': fw.colors[3],
                '--color5': fw.colors[4],
                '--color6': fw.colors[5]
              }}
            />
          ))}
          <div className="birthday-text">
            Happy Birthday Ahmed
          </div>
          <div className="message">
            with love, your friend Chiraz
            <span className="spark-move">✨</span>
          </div>
        </div>
      </div>
    );
  }

  if (step === 'window') {
    return (
      <div className={`App ${isBlurring ? 'blur-transition' : ''}`}>
        <div className="window-container intro-text">
          <div className="window-header">
            <div className="window-title">Message for Ahmed</div>
          </div>
          <div className="button-container">
            <button className="modern-button" onClick={handleYesClick}>
              Yes
            </button>
            <button
              className="modern-button no-button"
              onClick={handleNoClick}
              style={noButtonStyle}
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="intro-container">
        <div className={`intro-text ${isVisible ? 'visible' : ''}`}>
          {currentWord}
        </div>
      </div>
    </div>
  );
}

export default App;
