import React, { useState, useEffect, useRef } from 'react';
import './MonkeyAnimation.css';
import monkeySound from '../assets/chimp-monkey.mp3';

const MonkeyAnimation = () => {
  const [monkeys, setMonkeys] = useState([]);
  const audioRef = useRef(null);

  const addMonkey = () => {
    const newMonkey = {
      id: Date.now(),
      left: Math.random() * 100,
      bottom: Math.random() * 100
    };
    setMonkeys(prev => [...prev, newMonkey]);
    
    // Remove monkey after animation ends
    setTimeout(() => {
      setMonkeys(prev => prev.filter(m => m.id !== newMonkey.id));
    }, 4000);
  };

  useEffect(() => {
    // Flying monkeys interval
    const flyingInterval = setInterval(addMonkey, 1000);
    
    // Setup audio
    audioRef.current = new Audio(monkeySound);
    const audio = audioRef.current;
    audio.loop = true;

    // Play audio after a user interaction
    const playAudio = () => {
      audio.play().catch(error => console.log('Audio play failed:', error));
      document.removeEventListener('click', playAudio);
    };
    document.addEventListener('click', playAudio);

    return () => {
      clearInterval(flyingInterval);
      document.removeEventListener('click', playAudio);
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="monkey-container">
      {/* Flying monkeys */}
      {monkeys.map(monkey => (
        <div
          key={monkey.id}
          className="flying-monkey"
          style={{
            left: `${monkey.left}%`,
            bottom: `${monkey.bottom}%`
          }}
        >
          🐒
        </div>
      ))}
      
      {/* Jumping monkey */}
      <div className="jumping-monkey">
        🐒
      </div>
    </div>
  );
};

export default MonkeyAnimation;
